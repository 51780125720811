<template>
  <div class="col-12 col-lg-6 c g">
    <br><br>
    <div class="card card-body" style="border: 2px solid #ddd">
        <div class="col-12 text-center g">
            <h4>
                اشتراك في اداة المساعد الذكي للمشرفين
            </h4>
        </div>
        <div class="form-group g">
          <h5 for="">الاسم</h5>
          <input type="text"
            class="form-control" v-model="name">
        </div>
        <div class="form-group g">
          <h5 for="">رقم الجوال</h5>
          <input type="text"
            class="form-control" v-model="phone">
            <small>لارسال الفاتورة عليه</small>
        </div>
        <div class='col-12 g'>اشتراك <strong>3 اشهر</strong> بسعر <strong style="font-size: 18px">250 ريال</strong></div>
        <small class="g">سيتم اضافة 15% على المبلغ للضريبة</small>
        <div class="row">
            <div class="col-12 text-center">
                <button class="btn btn-primary" @click="done()" v-if="!loading">
                    <i class="fa fa-check"></i>
                    اشتراك الآن
                </button>
                <button class="btn btn-primary disabled" v-if="loading">
                    جاري التحميل...
                </button>
            </div>
        </div>
    </div>
    <div class="card card-body">
        <p>تنويه:</p>
        <small>
            <ul>
                <li>
                    اﻻشتراك صالح لحساب مدرستى واحد
                </li>
                <li>
                    يمكن تشغيل الاداة على اكثر من جهاز بنفس الاشتراك
                </li>
            </ul>
        </small>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox
  },
    data(){
        return {
            loading: false,
            name: "",
            phone: "",
            plan: 1,
            code: "",
            school_id: localStorage.getItem('school_id')
        }
    },
    methods: {
        done(){
            var g = this;
            if(!this.name || !this.phone){
                alert("برجاء كتابة الاسم ورقم الجوال")
                return;
            }
            this.loading = true
            $.post(api + '/api/subscribe/create-order', {
                school_id: g.school_id,
                name: this.name,
                phone: this.phone,
                m: true
            })
            .then(function(r){
                if (r.status != 100) {
                    g.loading = false
                    alert(r.response);
                    return;
                }
                $("body").append(`
                <form action="https://store.sdaa.com.sa/checkout" id="form" method="post">
                    <input type="hidden" name="website" value="naqar" />
                    <input type="hidden" name="data" value="${r.response.data}" />
                    <input type="hidden" name="token" value="${r.response.token}" />
                </form>
                `);
            $("#form").submit();
            })
            .fail(function(){
                g.loading = false
                alert("حدث خطأ")
            })
        }
    }
}
</script>
